<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="queryData" class="demo-form-inline search-form">
				<el-form-item label="用户名称：">
					<el-autocomplete
						v-model.trim="formInline.userName"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
					></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:showOperation="true"
				:operation-column-width="160"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #healthServiceGroup="{ scope }">
					<span @click="seeServiceList(scope.row)" class="seeServiceList">
						{{ scope.row.healthServiceGroup.title }}({{ (scope.row.healthServiceGroupingList && scope.row.healthServiceGroupingList.length) || 0 }})
					</span>
				</template>
				<template #operation="{ scope }">
					<el-button @click="seeMore(scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button @click="stopService(scope.row)" size="mini" type="warning" :disabled="scope.row.state == 'O'"> 结束服务 </el-button>
				</template>
			</Table>
		</div>
		<ShowList ref="showList"></ShowList>
		<createView ref="createView" />
	</div>
</template>

<script>
export default {
	name: 'signingManager',
	components: {
		Table: () => import('@/components/Table/table'),
		ShowList: () => import('./showList.vue'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				userName: '',
			},
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			options: [],
			tableData: [],
			tableHead: [
				{
					label: '编号',
					prop: 'applicationNumber',
					formatter: (row) => {
						return row.applicationNumber || '-';
					},
				},
				{
					label: '用户',
					prop: 'creator',
					formatter: (row) => {
						return row.creator?.title || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'description',
					formatter: (row) => {
						return row.creator?.description || '-';
					},
				},
				{
					label: '家庭信息',
					prop: 'householdMemberList',
					formatter: (row) => {
						return row.householdMemberList?.map((v) => v.title).toString() || '-';
					},
				},
				{
					label: '医护团队',
					prop: 'healthServiceGroup',
				},
				{
					label: '请求时间',
					prop: 'createTime',
				},
				{
					label: '状态',
					prop: 'state',
					formatter: (row) => {
						return row.state == 'P' ? '申请中' : row.state == 'A' ? '生效中' : row.state == 'U' ? '签约中' : row.state == 'O' ? '过期' : '-';
					},
				},
				{
					label: '生效时间',
					prop: 'validityStartTime',
					showOverflowTooltip: 0,
					formatter: (row) => {
						return row.validityStartTime && row.validityEndTime ? row.validityStartTime + '至' + row.validityEndTime : '-';
					},
				},
			],
			filterLoading: false,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			let data = {
				...this.queryData,
				...this.formInline,
				// state: 'A',
			};
			this.$http
				.get(this.api['HealthServiceApplications#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.creator?.title,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		stopService(row) {
			let data = {
				id: row.id,
				state: 'O',
			};
			this.$http
				.put(row._links.update.href, data)
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		seeServiceList(row) {
			if (!row.healthServiceGroupingList || row.healthServiceGroupingList.length == 0) return this.$message.warning('暂无医护团队信息');
			this.$refs.showList.showVisible = true;
			this.$refs.showList.tableData = row.healthServiceGroupingList || [];
		},
		seeMore(row) {
			this.$refs.createView.init(row);
			this.$refs.createView.link = row._links.update.href;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
	.seeServiceList {
		color: #1db9b1;
		cursor: pointer;
	}
}
</style>
